<template>
  <b-container fluid v-if="$root.$data.me.Username == 'erikbas'">
    <b-row>
      <b-col>
        <b-textarea v-model="src"></b-textarea>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button @click="all()"> All </b-button>

        <p>StatementReference</p>
        <ul>
          <li v-for="(spec, key) in specs" :key="key">
            {{ key }}: {{ spec }} 
          </li>
        </ul>

        <p>Matches</p>
        <b-container fluid> 
          <b-row v-for="(row, index) in rows" :key="index">
            <b-col class="m-2">
              <b-button @click="add(row)">{{ row.TrackReportingCode }} - {{ row.AlbumReportingCode }}</b-button>
              <pre>{{ row }}</pre>
            </b-col>
          </b-row>
        </b-container>
      </b-col>

      <b-col>
        <pre v-for="(err, index) in errors" v-html="err" :key="index" />
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
  export default {
    name: 'AccountingDebug',
    components: {
    },
    data () {
      return {
        src: '',
        errors: [],
      }
    },
    props: {
      id: String
    },
    methods: {
      async add (rule) {
        console.log(rule)
        try {
          await this.$http.post('accounting/matches', rule)
        } catch (err) {
          this.errors.push(rule)
        }
      },
      async all () {
        // Create all statement specs / beneficiaries that dont exists
        for (const ref in this.specs) {
          try {
            await this.$http.get('accounting/statement-specs/' + ref)
            console.log("Ref exists", ref)
          } catch (err) {
            if (err.response.status === 404) {
              const spec = this.specs[ref]

              // Check beneficiary
              try {
                await this.$http.get('accounting/beneficiaries/' + spec.Beneficiary) 
              } catch (err) {
                if (err.response.status === 404) {
                  console.log('Create beneficiary')
                  let response = this.$http.post('accounting/beneficiaries', {
                    BeneficiaryCode: spec.Beneficiary,
                    FullName: spec.Beneficiary
                  })
                  console.log(response)
                } else {
                  throw err
                }
              }

              // Create spec
              console.log('Creating spec')
              this.$http.post('accounting/statement-specs', {
                StatementReference: ref,
                BeneficiaryCode: spec.Beneficiary,
              })
            } else {
              throw err
            }
          }
        }
        
        for (const rule of this.rows) {
          try {
            await this.$http.post('accounting/matches', rule)
          } catch (err) {
            this.errors.push(rule)
          }
        }

        console.log("done!");
      },
    },
    computed: {
      rows () {
        let out = []
        for (let src of this.src.split('\n')) {
          if (!src) continue

          try {
            src = src.split('\t')
            let item = {
              AlbumReportingCode: src.shift().trim(),
              TrackReportingCode: src.shift().trim(),
              TransactionTypeID: src.shift(), 
              AccountingGeneration: 0, 
              FirstValidAccountingPeriodID: src.shift(),
              LastValidAccountingPeriodID: src.shift(), 
              ApplyToAlbumTrack: src.shift(), 
              ApplyToTrackOnly: src.shift(), 
              Rules: [],
            }
            
            for (let i = 0; i < src.length; i += 3) {
              if (!src[i]) continue
              item.Rules.push({
                // Beneficiary: src[i],
                StatementReference: src[i+1],
                SplitRate: src[i+2] / 100,
              })
            }
            out.push(item)
          } catch (e) {
            console.log(src.length, src)
            console.log(e)
          }
        }
        return out
      },
      specs () {
        const specs = {}
        for (let item of this.rows) {
          for (let rule of item.Rules) {
            specs[rule.StatementReference] = {
              Beneficiary: rule.StatementReference,
            }
          }
        }
        return specs
      },
    },
    mounted () {
    }
  }
</script>