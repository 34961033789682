var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.me.Username == "erikbas"
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-textarea", {
                    model: {
                      value: _vm.src,
                      callback: function($$v) {
                        _vm.src = $$v
                      },
                      expression: "src"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.all()
                        }
                      }
                    },
                    [_vm._v(" All ")]
                  ),
                  _c("p", [_vm._v("StatementReference")]),
                  _c(
                    "ul",
                    _vm._l(_vm.specs, function(spec, key) {
                      return _c("li", { key: key }, [
                        _vm._v(" " + _vm._s(key) + ": " + _vm._s(spec) + " ")
                      ])
                    }),
                    0
                  ),
                  _c("p", [_vm._v("Matches")]),
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    _vm._l(_vm.rows, function(row, index) {
                      return _c(
                        "b-row",
                        { key: index },
                        [
                          _c(
                            "b-col",
                            { staticClass: "m-2" },
                            [
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.add(row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(row.TrackReportingCode) +
                                      " - " +
                                      _vm._s(row.AlbumReportingCode)
                                  )
                                ]
                              ),
                              _c("pre", [_vm._v(_vm._s(row))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                _vm._l(_vm.errors, function(err, index) {
                  return _c("pre", {
                    key: index,
                    domProps: { innerHTML: _vm._s(err) }
                  })
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }